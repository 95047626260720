





























































































































































/* eslint-disable */
import { Vue, Component, Watch } from "vue-property-decorator";
import { OtherModule } from "@/store/other";
import { api_group } from "@/api";
import { numFormat } from "@/util/string";
import { Encryption } from "@/util/encryption";
@Component
export default class extends Vue {
  step = false;
  refresh = false;
  info: any = {
    chapter: {
      id: "",
    },
  };
  score_list = [];
  numFormat = numFormat;

  @Watch("$route", { immediate: true })
  watch_route() {
    this.answer_finish();
  }

  async show_score() {
    this.refresh = true;
    const res: any = await api_group.group_user_score({ group_id: this.id });
    this.score_list = res.results;
    this.refresh = false;
    this.step = true;
  }

  async answer_finish() {
    this.info = await api_group.answer_group_finish({
      group_id: this.id,
    });
  }

  get id() {
    return Number(Encryption.base_dec(this.$route.params.group_id));
  }
  get is_mobile() {
    return OtherModule.is_mobile;
  }
}
